.list{
    display: flex;
    width: 100%;
    
    /* Tablets */
    @media screen and (max-width: 1200px) {
        min-height: 100%; //pevents white spaces in the bottom
        min-width: 100%;
        overflow-y: scroll;
 }


    
    .listContainer{
        flex: 6;

        @media screen and (max-width: 1200px) {
            width: 100px;
           
    }

        .dataT{
            display: flex;
            padding: 20px;
            @media screen and (max-width: 1200px) {
                width: 100%;
               
        }
        }
    }
}