.events{
    display: flex;
    width: 100%;
    
    .eventsContainer{
        flex: 6;

        .evms{
            padding: 20px;
        }

        .tracksch{
            padding: 5px 20px;
        }
    }
}