.datatable {
  width: 1100px;
  margin: 0 auto;
  min-height: 100vh;
  overflow: auto;

  padding: 20px;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.married {
      background-color: rgba(0, 128, 0, 0.05);
      color: rgb(0, 128, 0);
    }
    &.divorced {
      background-color: rgba(255, 217, 0, 0.05);
      color: rgb(218, 165, 32);
    }
    &.single {
      background-color: rgba(255, 0, 221, 0.103);
      color: rgb(212, 7, 144);
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 20px;

    .viewButton {
      padding: 2px 5px;
      color: darkblue;
      cursor: pointer;
    }

    .editButton {
      padding: 2px 5px;
      color: green;
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      color: crimson;
      cursor: pointer;
    }
  }

  /* Tablets */
  @media screen and (max-width: 768px) {
    .dataWrapper {
      width: 100%;
      margin: 0 auto;
      height: 100%;
      overflow: auto;
    }
  }
}
