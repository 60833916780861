.searchchat {
  border-bottom: 1px solid gray;

  .searchForm {
    padding: 10px;
    input {
      background-color: transparent;
      border: none;
      color: white;
      outline: none;

      &::placeholder {
        color: lightgray;
      }
    }
  }

  .userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #01693c;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
