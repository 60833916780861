.app.dark {
  background-color: #01693c;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .navbar {
    color: #f2f2f2;
    border-color: #f2f2f2a6;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #01693c;

    .top {
      .logo {
        color: #000045;
      }
    }

    hr {
      border-color: #f2f2f2a6;
    }

    .center {
      ul {
        li {
          &:hover {
            background-color: #333;
          }
        }
      }

      .icon {
        color: #000045;
      }

      span {
        color: #f2f2f2;
      }
    }
  }

  .datatable {
    .datagrid {
      color: whitesmoke;
      border: none;

      .cellWithStatus {
        color: gray;
      }
    }
  }

  input {
    background-color: transparent;
  }

  .waitingroom {
    .rooms {
      span {
        color: #f2f2f2;
      }
    }
  }
}
