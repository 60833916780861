.navbar {
  height: 70px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  position: absolute;

  /* Tablets */
  @media screen and (max-width: 768px) {
    // display: none;
    width: 100%;
  }

  /* Mobile */
  @media screen and (max-width: 568px) {
    width: 100%;
  }

  .wrapper {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    /* Mobile */
    @media screen and (max-width: 568px) {
      justify-content: flex-end;
    }

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;
      border-radius: 10px;

      /* Mobile */
      @media screen and (max-width: 568px) {
        display: none !important;
      }

      /* Tablet */
      @media screen and (max-width: 768px) {
        margin-left: 72px;
      }

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 14px;
          font-weight: 700px !important;
        }
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 20px;
          cursor: pointer;
        }

        .icondark {
          cursor: pointer;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .counter {
          width: 25px; //15px;
          height: 15px;
          background-color: red;
          border-radius: 30%; //50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -15px; //-5px;
        }
      }
    }

    .itemsL {
      margin-left: 24px;
    }
  }
}

//  Remove this with the right css optimization
.overlayD {
  margin-top: 70px;
}
