.alarms{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 5rem;
    border-collapse: collapse;
    max-width: 1100px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    background-image: url("../../bgImg/3d-rendering-alarm-clock.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center !important;
}