.chart{
    flex: 4;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;
    font-weight: 700;
    border-radius: 12px;

    .title{
        margin-bottom: 20px;
    }

    .chartGrid{
        stroke: rgb(228, 255, 255);
    }
}