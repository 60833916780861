// Basically pass an include in those components to make them responsive

@mixin mobile{
    @media screen and (max-width: 480px) {
        @content
    }
}
@mixin tablet{
    @media screen and (max-width: 760px) {
        @content
    }
}
@mixin laptop{
    @media screen and (max-width: 1200px) {
        @content
    }
}

.chat{
    display: flex;
    width: 100%;
    
    .chatContainer{
        flex: 6;
    }
}