
 .texting{
    height: 100vh;
    padding: 20px;
    // background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;

    /*Mobile*/
@media screen and (max-width: 568px) {
   width: 100%;
}

    .container{
        border-radius: 10px;
        width: 90%;
        height: 100%;
        display: flex;
        overflow: hidden;
    }
}