.formContainer {
  background-image: url("../../bgImg/baby.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .formWrapper {
    background-color: #01693c;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .logo {
      color: #f2f2f2;
      font-weight: bold;
      font-size: 24px;
    }

    .title {
      color: #f2f2f2;
      font-size: 12px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        padding: 15px;
        border: none;
        width: 250px;
        border-radius: 12px;
        border-bottom: 1px solid #01693c;

        &::placeholder {
          color: rgb(175, 175, 175);
        }
      }

      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .icon {
          color: #7b96ec;
          cursor: pointer;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          color: #888;
          margin-left: 15px;
        }
      }

      button {
        background-color: #7b96ec;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        border-radius: 12px;
        cursor: pointer;
      }
    }
    p {
      color: #f2f2f2;
      font-size: 15px;
      margin-top: 10px;
    }
  }
}
