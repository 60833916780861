.sidebar {
  flex: 1;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  color: #555;
  min-height: 100vh;
  align-self: flex-start;
  position: fixed;
  top: 0px;
  margin-right: 8px;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 24px;
  background: linear-gradient(
    106.5deg,
    rgba(255, 215, 185, 0.91) 23%,
    rgba(223, 159, 247, 0.8) 93%
  );
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: all 300ms ease;

  /* Tablets */
  @media screen and (max-width: 768px) {
    top: 0;
    flex: 0.25;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    overflow: hidden;
  }

  /*  Mobile */
  @media screen and (max-width: 568px) {
    position: fixed;
    width: 8%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #01693c;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      img {
        width: 24px;
        height: 24px;
      }

      p {
        padding-top: 12px;
      }
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
    border-color: #ffd700;
  }

  .center {
    padding-left: 10px;
    /* Tablets */
    @media screen and (max-width: 768px) {
      padding-left: 2px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      /*  Mobile */
      @media screen and (max-width: 568px) {
        p {
          display: none;
        }
      }

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        margin-bottom: 12px;

        /*  Mobile */
        @media screen and (max-width: 568px) {
          align-items: center;
          margin-right: 8px;
          justify-content: center;
          margin-bottom: 24px;
        }

        &:hover {
          width: auto;
          cursor: pointer;
          background: #800020;
          border-radius: 0.7rem;

          /*  Mobile */
          @media screen and (max-width: 568px) {
            width: max-content;
          }
        }
      }

      .icon {
        font-size: 20px;
        color: #01693c;
      }
      span {
        font-size: 16px;
        font-weight: 600;
        color: #888;
        margin-left: 10px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke; //light-mode
      }
      &:nth-child(2) {
        background-color: #01693c; //dark-mode
      }
    }
  }

  .menuItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    border-radius: 0.7rem;
    font-size: 14px;
  }

  .menuItem:hover {
    cursor: pointer;
  }

  .menu .menuItem:last-child {
    position: absolute;
    bottom: 7.8rem !important;
    width: 100%;
    /* Tablets */
    @media screen and (max-width: 1200px) {
      bottom: 12.8rem !important;
    }
  }

  .active {
    background: #800020;
    margin-left: 0;
  }
  .active::before {
    content: "";
    width: 8px;
    height: 100%;
    background: #ff919d;
    margin-right: calc(1rem - 8px);
  }

  /* Tablets */
  @media screen and (max-width: 768px) {
    .top {
      .logo {
        p {
          display: none;
        }
      }
    }

    .center {
      ul {
        span {
          display: none;
        }
        .title {
          display: none;
        }
      }
    }
  }
}

/* Mobile */
@media screen and (max-width: 568px) {
  .bars {
    display: flex;
    position: fixed;
    top: 1.5rem;
    margin-left: 2rem;
    z-index: 99;
    background: #ff919d;
    padding: 10px;
    border-radius: 10px;
  }
  .close {
    left: -60%;
  }
}
