.treatmentsheets{
    display: flex;
    width: 100%;
    background-color: white;

    
    .treatmentSheetsContainer{
        flex: 6;
    }

    .sheetsment{
        padding: 20px;
    }

    .iuisheets{
        padding: 5px 20px;
    }

    .recpsheets{
        padding: 5px 20px;
    }

    .stimulsheets{
        padding: 5px 20px;
    }
}