.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../bgImg/babyStickers.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;

  @media screen and (max-width: 320px) {
    background-size: 560px 100vh !important;
    background-position: center !important;
  }

  @media screen and (max-width: 550px) {
    background-size: 500px 100vh !important;
    background-position: center !important;
  }

  .formWrapper {
    background-color: #01693c;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 320px) {
      padding: 10px;
    }

    @media screen and (max-width: 550px) {
      padding: 12px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        padding: 8px;
        width: 200px;
        height: 30px;
        margin: 10px;
        border: none;
        border-radius: 12px;
        border-bottom: 1px solid #01693c;
      }
      button {
        width: 210px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: #7b96ec;
        color: white;
        font-weight: bold;
        cursor: pointer;

        @media screen and (max-width: 320px) {
          width: 68px;
        }

        @media screen and (max-width: 550px) {
          width: 80px;
        }
      }

      p {
        Link {
          color: #f2f2f2;
        }
      }

      span {
        font-size: 12px;
        color: #f2f2f2;
        margin-top: 10px;
      }
    }
  }
}
