.navbarP{
    height: 70px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;

    /* Tablets */
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    
     /* Mobile */
     @media screen and (max-width: 568px) {
        width: 100%;
    }

    .wrapper{
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

    
    .search{
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding: 3px;

        input {
            border: none;
            outline: none;
            background: transparent;

            &:placeholder{
                font-size: 12px;
            }
        }
    }

    .usersc{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
        
        p{
            font-size: 18px;
            font-weight: 900;

        }
    }


    .items{
        display: flex;
        align-items: center;

            .item{
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;

                .icon{
                    font-size: 20px;
                }

                .avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .counter{
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right:  -5px;
                }
            }
        }
    }
}