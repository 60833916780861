.single{
    display: flex;
    width: 100%;
    background-color: white;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
    
    .singleContainer{
        flex: 6;
    
        .spu{
            padding: 5px 20px;

              /* Tablet */
              @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .csfile{
            padding: 5px, 20px;
        }
    }

}
