.chatbar{
    flex: 1;
    background-color: #ca4300e3;
    border-radius: 10px;
    height: 100%;
    overflow:auto;

     /*Mobile*/
@media screen and (max-width: 568px) {
    display: none;
 }
}