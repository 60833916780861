.billing{
    display: flex;
    width: 100%;
    background-color: white;
    
    .billingContainer{
        flex: 6;
    }

    .account{
        padding: 20px;
    }

    .bnote{
        padding: 20px;
    }
    .bnt{
        padding: 5px 20px;
    }
    .tbills{
        padding: 5px 20px;
    }
}