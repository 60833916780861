.reporturinalysis {
  flex: 4;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  border-radius: 10px;

  .coverageury {
    margin: auto;
    border-collapse: collapse;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    .overallury {
      .headerury {
        padding: 20px;
        margin: auto;
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 5px;
        height: 24px;
        max-width: 500px;
        margin-top: 30px;
        background-color: #01693c;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        p {
          color: whitesmoke;
          text-align: right;
          margin-top: 15px;
          font-weight: 900;
          font-size: 18px;
        }
      }

      .contentury {
        border-collapse: collapse;
        margin: auto;
        font-size: 0.9em;
        font-family: sans-uryif;
        max-width: 500px;
        .ury {
          padding: 20px;
          margin: auto;
          height: 24px;
          max-width: 500px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            color: gray;
            text-align: right;
            font-weight: 600;
            font-size: 18px;
            margin-left: 68px;
            margin-top: -32px;
          }

          span {
            p {
              margin-left: 150px;
            }
          }
        }
      }
    }
  }

  .btn-pri {
    border: none;
    color: whitesmoke;
    border-radius: 5px;
  }
  .btn-prime {
    border: none;
    color: whitesmoke;
    background-color: #0d6efd;
    width: 48px;
    height: 36px;
    border-radius: 5px;
  }

  .buttonflex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
  }
}
