.embryo {
  flex: 4;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  border-radius: 10px;

  /* Tablet */
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .embryoPresvWrapper {
    width: 900px;
    margin: 0 auto;
    height: 100%;
    overflow: auto;

    /* Tablet */
    @media screen and (max-width: 768px) {
      width: 600px;
      margin: 0;
    }

    .styled-table {
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      max-width: 1400px;
      border-radius: 12px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
    .styled-table thead tr {
      background-color: #01693c;
      color: #f2f2f2;
      text-align: left;
    }

    .styled-table th {
      padding: 12px 15px;
    }

    .styled-table td {
      padding: 12px 15px;
    }

    .styled-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    .icon {
      color: #01693c;
      border: none;
    }
  }
  .alarmSet {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      margin-left: 8px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
