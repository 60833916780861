.mhistory {
  padding: 20px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;

  /* Tablet */
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  h1 {
    color: #01693c;
  }
}
