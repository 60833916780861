.casefile {
  padding: 20px;
  overflow: auto;

  .profile {
    padding: 10px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 300px;
    flex: 1;
    background-color: #01693c;

    /* Tablets */
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    /* Mobile */
    @media screen and (max-width: 568px) {
      width: 100%;
    }

    img {
      border-radius: 50%;
      width: 128px;
      height: 128px;
      border: 12px solid #f2f2f2;
      margin: auto;
      display: block;
    }

    .userinfo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      margin-bottom: 5px;

      h1 {
        font-size: 24px;
        font-weight: bold;
        color: #f2f2f2;
      }

      h2 {
        font-size: 20px;
        font-weight: bold;
        color: #f2f2f2;
      }

      h3 {
        font-size: 18px;
        font-weight: bold;
        color: #f2f2f2;
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
        color: #f2f2f2;
      }

      .icon {
        color: #f2f2f2;
      }

      .icon3 {
        color: #f2f2f2;
        font-size: 24px;
      }

      .icon4 {
        color: #f2f2f2;
        font-size: 14px;
      }
    }
  }

  .userprofile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;

    /* Tablets */
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .username {
      display: flex;
      padding: 10px;
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.596);
      border-radius: 10px;
      height: 200px;
      width: 500px;

      .usersinfos {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-bottom: 5px;

        strong {
          color: grey;
        }

        h1 {
          font-size: 20px;
          font-weight: 900;
          color: #01693c;
        }
      }
    }
    .details {
      display: flex;
      padding: 10px;
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      height: 200px;
      width: 500px;

      .usersinfos {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-bottom: 5px;

        strong {
          color: grey;
        }

        h1 {
          font-size: 20px;
          font-weight: 900;
          color: #01693c;
        }
      }
    }
  }
}
