.inputchat {
  height: 50px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    border: none;
    outline: none;
    color: #2f2d52;
    font-size: 18px;

    &::placeholder {
      color: lightgray;
    }
  }

  .send {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        color: lightgray;
        cursor: pointer;
      }
    }

    button {
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      color: whitesmoke;
      background-color: #01693c;
    }
  }
}
