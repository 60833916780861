.consultation{
    display: flex;
    width: 100%;
    background-color: white;

    
    .consultationContainer{
        flex: 6;
    }

    .cnt{
        padding: 20px;
    }

    .cno{
        padding: 5px 20px;
    }


    .sono{
        padding: 5px 20px;
    }
    
    .tpcharts{
        padding: 5px 20px;
    }
}