.embryoreport {
  flex: 4;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  border-radius: 10px;

  /* Tablet */
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .embryoWrapper {
    width: 900px;
    margin: 0 auto;
    height: 100%;
    overflow: auto;

    /* Tablet */
    @media screen and (max-width: 768px) {
      width: 600px;
      margin: 0;
    }

    .styled-table {
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      max-width: 800px;
      border-radius: 12px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
    .styled-table thead tr {
      background-color: #01693c;
      color: #f2f2f2;
      text-align: left;
    }

    .styled-table th {
      padding: 12px 15px;
    }

    .styled-table td {
      padding: 12px 15px;
    }

    .styled-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    .icon {
      color: #01693c;
      border: none;
    }
  }
}
