.atrck {
  flex: 4;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  border-radius: 10px;

  hr {
    margin-top: 15px;
    margin-right: 15px;
  }

  .eventstrack {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 20px;

    .notifyIcon {
      color: #01693c;
      font-size: 48px;
    }

    .customDate {
      border-radius: 12px;
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      background-color: #01693c;
      p {
        color: white;
        padding: 5px;
        margin-top: 5px;
        font-weight: 500;
      }
    }
  }

  /* Tablets */
  @media screen and (max-width: 1200px) {
    .calendarWrapper {
      width: 100%;
      margin: 0 auto;
      height: 100%;
      overflow: auto;
    }
  }
}
