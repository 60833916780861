.waitingroom{
    flex: 4;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;
    border-radius: 10px;
    overflow:auto;

    /*Mobile*/
    @media screen and (max-width: 568px) {
        width: 100%;
       }

    hr{
        margin-top: 15px;
        margin-right: 15px;
    }

    .rooms{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        position: relative;

        input{
            margin-right: 10px;
        }

        p{
            margin-top: 10px;
        }

        .done{
            text-decoration: line-through;
        }

        .buttonfloat{
            position: absolute;
            right:    0;
            bottom:   0;

        }

        .btn-primary{
            color: white;
            background-color: blue;
            border: none;
            border-radius: 3px;
        }


        .btn-secondary{
            color: white;
            background-color: crimson;
            border: none;
            border-radius: 3px;
        }

        button{
            margin: 5px;

        }
    }

    
                    /*Mobile*/
                    @media screen and (max-width: 568px) {
                        .rooms{
                            span{
                                max-width: 200px;
                                margin-bottom: 50px;
                            }
                        }
                    }
   
}

/*Mobile*/
@media screen and (max-width: 568px) {
    .pageWrapper{
       display: none;
    }
}
