.spouse {
  padding: 20px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  width: 1100px;
  margin: 0 auto;
  overflow: auto;
  overflow-y: scroll;

  /*  Tablet  */
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
  /*  Tablet  */
  @media screen and (max-width: 568px) {
    width: 100%;
    margin: 0;
  }

  /*  Tablet  */
  @media screen and (max-width: 768px) {
    .spouseWrapper {
      width: 600px;
      height: 100%;
      overflow: auto;
    }
  }
  /*  Tablet  */
  @media screen and (max-width: 568px) {
    .spouseWrapper {
      width: 500px;
      height: 100%;
      margin: 0;
      overflow: auto;
    }
  }

  h1 {
    color: #01693c;
    /*  Tablet  */
    @media screen and (max-width: 768px) {
      font-weight: 900;
    }

    /*  Mobile  */
    @media screen and (max-width: 768px) {
      margin-left: 2rem;
    }
  }
}
