.bnote{
        flex: 4;
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        color: gray;
        border-radius: 10px;

         /* Tablet */
      @media screen and (max-width: 768px) {
        width: 100%;
    }

        .addcnote{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;
                position: relative;
        
                input{
                    margin-right: 10px;
                }
        
                p{
                    margin-top: 10px;
                }
        
                .done{
                    text-decoration: line-through;
                }
        
                .buttonfloat{
                    position: absolute;
                    right:    0;
                    bottom:   0;
                }
        
                .btn-primary{
                    color: white;
                    background-color: blue;
                    border: none;
                    border-radius: 3px;
                }
        
                button{
                    margin: 5px;
                }
            }
    
            .modal{
                input ::placeholder{
                    color: green;
                }
            }
             /* Tablet */
      @media screen and (max-width: 768px) {
        .billnoteWrapper{
            width: 600px;
            height: 100%;
            overflow:auto;
        }
    }
}