.cstform{
    display: flex;
    width: 100%;
    background-color: white;

    
    .cstformContainer{
        flex: 6;
    }

    .cstf{
        padding: 5px 20px;
    }
}