.home{
    display: flex;
    line-height: 0%;
    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    .homeContainer{
        flex: 6;

        .widgets, .charts{
            display: flex;
            padding: 20px;
            gap: 20px;

            /* Tablet */
            @media screen and (max-width: 768px) {
                width: 100%;
                flex-direction: column;
            }

            
            /*  Mobile */
            @media screen and (max-width: 568px) {
                width: 100%;
                }

        }

        .charts{
            padding: 5px 20px;
        }
    }
}