.new{
    display: flex;
    width: 100%;

    .newContainer{
        flex: 6;

    .top,
    .bottom{
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;  
      display: flex;
      border-radius: 50px;

    h1{
        color: lightgray;
        font-size: 28px;
    }
    
    .left{
        flex: 1;
        text-align: center;

        img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: contain;
        }

    }

    .right{
        flex: 2;
        form{
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            justify-content: space-around;

            .formInput{
                width: 40%;

                label{
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .icon{
                        cursor: pointer;
                    }
                }

                input{
                    width: 100%;
                    padding: 5px;
                    border: none;
                    border-radius: 12px;
                    border-bottom: 1px solid gray;
                }
            }

            button{
                width: 150px;
                padding: 10px;
                border: none;
                background-color: teal;
                color: white;
                border-radius: 5px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
            }
        }
    }

    }
    }
}