.trackreview {
  flex: 4;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  border-radius: 10px;

  /* Tablet */
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .coveragetrv {
    margin: auto;
    border-collapse: collapse;
    max-width: 1200px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    /* Tablet */
    @media screen and (max-width: 768px) {
      max-width: 1200px;
    }
    .overalltrv {
      .headertrv {
        padding: 20px;
        margin: auto;
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 5px;
        height: 24px;
        max-width: 1200px;
        margin-top: 30px;
        background-color: #01693c;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        /* Tablet */
        @media screen and (max-width: 768px) {
          max-width: 1200px;
        }

        p {
          color: whitesmoke;
          text-align: right;
          margin-top: 15px;
          font-weight: 900;
          font-size: 18px;
        }
      }

      .contenttrv {
        border-collapse: collapse;
        font-size: 0.9em;
        font-family: sans-serif;
        max-width: 1200px;

        /* Tablet */
        @media screen and (max-width: 768px) {
          max-width: 1200px;
        }
        .cms {
          padding: 20px;
          height: 24px;
          max-width: 1200px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          /* Tablet */
          @media screen and (max-width: 768px) {
            max-width: 1200px;
          }

          p {
            color: gray;
            text-align: right;
            font-weight: 600;
            font-size: 18px;
            margin-top: -32px;
          }
        }
      }
    }
  }

  .embryoFreeze {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input {
      margin-bottom: 12px;
      transform: scale(2);
    }
  }

  .sign {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .signData {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 32px;
  }

  .crp {
    margin-left: 24px;
  }

  .btn-pri {
    border: none;
    color: whitesmoke;
    border-radius: 5px;
  }
  .btn-prime {
    border: none;
    color: whitesmoke;
    background-color: #0d6efd;
    width: 48px;
    height: 36px;
    border-radius: 5px;
  }

  .buttonflex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
  }

  /* Tablet */
  @media screen and (max-width: 768px) {
    .rorWrapper {
      width: 600px;
      height: 100%;
      overflow: auto;
    }
  }
}
