.viewreceipt {
  margin: auto;
  padding: 20px;
  border-collapse: collapse;
  max-width: 400px;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  .receiptbody {
    img {
      width: 36px;
      height: 36px;
    }

    .headerRule {
      border: 5px solid #01693c;
      border-radius: 5px;
    }
  }
}
