.viewreportlftb {
  margin: auto;
  padding: 20px;
  border-collapse: collapse;
  max-width: 1100px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  .headerlftb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    h1 {
      text-align: center;
      font-family: Arial, Helvetica, sans-lftbif;
      color: #01693c;
      font-weight: bold;
      font-size: 28px;
    }
    span {
      .icon {
        color: #01693c;
      }
      i {
        font-weight: bold;
      }
    }
  }

  hr {
    border: 5px solid #01693c;
    border-radius: 5px;
  }

  .lftbpatientsdetail {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    p {
      font-size: 14px;

      strong {
        color: #01693c;
      }
    }
  }

  .coveragelftb {
    margin: auto;
    border-collapse: collapse;
    max-width: 700px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    .overalllftb {
      .headerlftb {
        padding: 20px;
        margin: auto;
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 5px;
        height: 24px;
        max-width: 700px;
        margin-top: 30px;
        background-color: #01693c;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        p {
          color: whitesmoke;
          text-align: right;
          margin-top: 15px;
          font-weight: 900;
          font-size: 18px;
        }
      }

      .contentlftb {
        border-collapse: collapse;
        margin: auto;
        font-size: 0.9em;
        font-family: sans-lftbif;
        max-width: 700px;
        .lftb {
          padding: 20px;
          margin: auto;
          height: 24px;
          max-width: 700px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            color: gray;
            text-align: right;
            font-weight: 600;
            font-size: 18px;
            margin-left: 68px;
            margin-top: -32px;
          }

          span {
            p {
              margin-left: 150px;
            }
          }
        }
      }
    }
  }
}
